import React, { useEffect, useRef } from 'react';

import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { tns } from 'tiny-slider/src/tiny-slider';

import PageWrapper from 'Layouts/PageWrapper';
import SEO from 'Components/SEO';

import 'tiny-slider/src/tiny-slider.scss';

export default ({ data }) => {
  const { edges: images } = data.allMarkdownRemark;

  const container = useRef(null);

  useEffect(() => {
    const containerCur = document.querySelector('.team__slider');

    if (!container.current) {
      return () => {};
    }

    const slider = tns({
      container: containerCur,
      gutter: 20,
      controlsPosition: 'top',
      controlsText: [ '', '' ],
      autoplay: true,
      autoplayButtonOutput: false,
      navPosition: 'bottom',
      arrowKeys: true,
      loop: true,
      mouseDrag: true,
    });

    return () => {
      slider.destroy();
    };
  }, [ container ]);

  return (
    <PageWrapper>
      <SEO title="Group Pictures" />
      <main className="page__main page__main--simple">
        <h1 className="page__title">Group Pictures</h1>
        <div className="team__slider__wrapper">
          <div className="team__slider" ref={ container }>
            { images.map(({ node: image }, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="team__slide" key={ key }>
                <h3 className="team__slide__title">{ image.frontmatter.publicationYear }</h3>
                { !!image?.frontmatter?.image?.childImageSharp && (
                  <Img
                    className="team__slide__img"
                    fluid={ image.frontmatter.image.childImageSharp.fluid }
                  />
                ) }
              </div>
            )) }
          </div>
        </div>
      </main>
    </PageWrapper>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: {eq: "group_pictures"}
        }
      },
      sort: {
        fields: frontmatter___publicationYear,
        order: DESC
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            publicationYear
            image {
              childImageSharp {
                fluid(maxWidth: 1240, quality: 65) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
